import React from "react";
import TextLanguage from "../../../data/TextLanguage.json";
import {
    Button,
    Typography,
    Tooltip,
    IconButton,
    Badge,
    useTheme,
    CircularProgress
} from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ErrorIcon from '@mui/icons-material/Error';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MailIcon from '@mui/icons-material/Mail';
import { useSearchParams } from "react-router-dom";




const docsColor = 'rgba(225,0,0,0.35)';
const gopColor = 'rgba(208, 77, 1, 0.9)';
const proformaColor = 'rgb(9, 95, 200)';
const invoiceColor = 'rgba(146, 156, 2, 0.84)';




export const DashboardFilterButtons = ({
    searchFilter, setSearchFilter,
    patientsQuery, lang,

}) => {

    const theme = useTheme();

    const [searchParams, setSearchParams] = useSearchParams({ sortField: 0 , sortOrder: 0});
    const handleSort = (event) => {
        setSearchParams(prev => {
            prev.set('sortField', event.sortField);
            prev.set('sortOrder', event.sortOrder);
            return prev;
        }, { replace: true });
    }

    let DisplayDocsCount = 0;
    let DisplayProformaCount = 0;
    let DisplayGOPCount = 0;
    let DisplayInvoiceCount = 0;

    const patientDashboard = patientsQuery?.data?.data?.dashboard;

    if (patientDashboard) {
        DisplayDocsCount = !searchFilter?.missingDocs ? (patientDashboard?.totalPatientCount - patientDashboard?.missingDocsCount) : (patientDashboard?.missingDocsCount);
        DisplayProformaCount = !searchFilter?.missingProforma ? (patientDashboard?.totalPatientCount - patientDashboard?.missingProformaCount) : (patientDashboard?.missingProformaCount);
        DisplayGOPCount = !searchFilter?.missingGOP ? (patientDashboard?.totalPatientCount - patientDashboard?.missingGOPCount) : (patientDashboard?.missingGOPCount);
        DisplayInvoiceCount = !searchFilter?.missingInvoice ? (patientDashboard?.totalPatientCount - patientDashboard?.missingInvoiceCount) : (patientDashboard?.missingInvoiceCount);
    }


    const handleErrorFlagFilter = () => {
        const { missingDocs, missingProforma, missingGOP, missingInvoice, FlagMessage, fUpload, ...updatedSearchFilter } = searchFilter;
        setSearchFilter({ ...updatedSearchFilter,  FlagError:  true   });
    }
    const handleUploadFilter = () => {
        const { missingDocs, missingProforma, missingGOP, missingInvoice, FlagMessage, FlagError, ...updatedSearchFilter } = searchFilter;
        setSearchFilter({ ...updatedSearchFilter,  fUpload:  true   });
    }

    const handleMessageFlagFilter = () => {
        const { missingDocs, missingProforma, missingGOP, missingInvoice, FlagError, fUpload, ...updatedSearchFilter } = searchFilter;
        setSearchFilter({ ...updatedSearchFilter,  FlagMessage: true   });
        handleSort({ sortOrder: 0 ,sortField: null });
    }

    const handleTotalPatientsFilter = () => {
        const { missingDocs, missingProforma, missingGOP, missingInvoice, FlagMessage, FlagError, fUpload, ...updatedSearchFilter } = searchFilter;
        setSearchFilter(updatedSearchFilter);
    };

    const handleMissingDocFilter = () => {
        const { missingProforma, missingGOP, missingInvoice, FlagMessage, FlagError, fUpload, ...updatedSearchFilter } = searchFilter;

        if (searchFilter?.missingDocs) {
            setSearchFilter({ ...updatedSearchFilter, missingDocs:  false  });
        } else {
            setSearchFilter({ ...updatedSearchFilter,  missingDocs: true  });
        }
    };


    const handleMissingProformaFilter = () => {
        const { missingDocs, missingGOP, missingInvoice, FlagMessage, FlagError, fUpload, ...updatedSearchFilter } = searchFilter;

        if (searchFilter?.missingProforma) {
            setSearchFilter({ ...updatedSearchFilter, missingProforma:  false  });
        } else {
            setSearchFilter({ ...updatedSearchFilter, missingProforma: true });
        }
    }

    const handleMissingGOPFilter = () => {
        const { missingDocs, missingProforma, missingInvoice, FlagMessage, FlagError, fUpload, ...updatedSearchFilter } = searchFilter;

        if (searchFilter?.missingGOP) {
            setSearchFilter({ ...updatedSearchFilter,  missingGOP: false   });
        } else {
            setSearchFilter({ ...updatedSearchFilter, missingGOP:  true });
        }
    };
    const handleMissingInvoiceFilter = () => {
        const { missingDocs, missingProforma, missingGOP, FlagMessage, FlagError, fUpload, ...updatedSearchFilter } = searchFilter;

        if (searchFilter?.missingInvoice) {
            setSearchFilter({ ...updatedSearchFilter, missingInvoice:  false  });
        } else {
            setSearchFilter({ ...updatedSearchFilter,  missingInvoice:   true  });

        }
    };









    return (<>

        <IconButton onClick={handleErrorFlagFilter}
            sx={{ borderRadius: '10px 0px 0px 10px', borderRight: '2px solid rgb(160,160,160)' }} className={`counterBox ${searchFilter?.FlagError ? 'counterBoxActive' : null}`}>
            <ErrorIcon htmlColor='#ff9900' sx={{ fontSize: '1.75rem', m: '0px 5px 0px 5px' }} />
        </IconButton>

        <IconButton onClick={handleUploadFilter}
            sx={{ borderRadius: '0px', borderRight: '2px solid rgb(160,160,160)' }} className={`counterBox ${searchFilter?.fUpload ? 'counterBoxActive' : null}`}>
            <UploadFileIcon htmlColor='#ceea75' sx={{ fontSize: '1.75rem', m: '0px 5px 0px 5px' }} />
        </IconButton>


        <IconButton onClick={handleMessageFlagFilter}
            sx={{ borderRadius: '0px', borderRight: '2px solid rgb(160,160,160)' }} className={`counterBox ${searchFilter?.FlagMessage ? 'counterBoxActive' : null}`}>


            {(patientDashboard && patientDashboard?.newMessageCount > 0) ?
                <Badge badgeContent={patientDashboard?.newMessageCount} size='small' max={9} sx={{ transform: 'scale(0.95) translateY(4px)' }} color='error'
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MailIcon className='newMessageFlag' sx={{ fontSize: '1.75rem', m: '0px 5px 0px 5px' }} htmlColor='lightgreen' />
                </Badge>
                :
                <MailIcon sx={{ transform: 'translateY(1px)' }} htmlColor='white' />}

        </IconButton>
        <Tooltip title={<Typography>{TextLanguage["TotalPatients"][lang]}</Typography>} placement="top">
            <Button onClick={handleTotalPatientsFilter}
            className={`counterBox ${!(searchFilter?.FlagMessage !== undefined || searchFilter?.FlagError !== undefined || searchFilter?.missingDocs !== undefined || searchFilter?.missingProforma !== undefined || searchFilter?.missingGOP !== undefined || searchFilter?.missingInvoice !== undefined || searchFilter?.fUpload !== undefined)
                ? "counterBoxActive"
                : null}`} sx={{ m: '0px 10px 0px 0px', borderRadius: '0px 10px 10px 0px' }}>

                <PeopleAltIcon htmlColor='rgb(5, 61, 30)' sx={{ fontSize: '1.75rem', m: '0px 5px 0px 5px' }} />
                <div className='counterTextBox' style={{ m: '0px 0px 0px 5px' }}>


                    <span className='counterTextData'>
                        {patientsQuery.isFetching ? <CircularProgress size={16} /> : `${patientDashboard ? patientDashboard?.totalPatientCount : 0}`}
                    </span>

                </div>

            </Button>
        </Tooltip>
        <svg height="35" width="4">
            <line x1="0" y1="0" x2="0" y2="50" stroke="white" strokeWidth="4" />
        </svg>
        <Tooltip title={searchFilter?.missingDocs === false ?
            <Typography>
                {TextLanguage["Uploaded"][lang]}
                {' '}
                {TextLanguage["Documents"][lang]}
            </Typography>
            :
            <Typography>
                {TextLanguage["Awaiting"][lang]}
                {' '}
                {TextLanguage["Documents"][lang]}
            </Typography>} placement="top">
            <Button onClick={handleMissingDocFilter} className={`counterBox ${searchFilter?.missingDocs !== undefined ?
                searchFilter?.missingDocs ? "counterBoxActiveMissing" : "counterBoxActive" : null}`} sx={{ marginLeft: '10px' }}>
                <DescriptionIcon htmlColor={theme.palette.dashboardTheme.Docs} sx={{ m: '0px 0px 0px 0px', fontSize: '1.5rem' }} />
                <div className='counterTextBox'>

                    <span className='counterTextData'>
                        {patientsQuery.isFetching ? <CircularProgress size={16} /> : `${DisplayDocsCount}`}
                    </span>
                </div>
            </Button>
        </Tooltip>
        <ChevronRightIcon />
        <Tooltip title={searchFilter?.missingGOP === false ?
            <Typography>
                {TextLanguage["Uploaded"][lang]}
                {' '}
                {TextLanguage["GOP"][lang]}
            </Typography>
            :
            <Typography>
                {TextLanguage["Awaiting"][lang]}
                {' '}
                {TextLanguage["GOP"][lang]}
            </Typography>} placement="top">
            <Button onClick={handleMissingGOPFilter} className={`counterBox ${searchFilter?.missingGOP !== undefined ?
                searchFilter?.missingGOP ? "counterBoxActiveMissing" : "counterBoxActive" : null}`}>
                <ReceiptIcon htmlColor={theme.palette.dashboardTheme.GOP} sx={{ m: '0px 0px 0px 0px', fontSize: '1.5rem' }} />
                <div className='counterTextBox'>

                    <span className='counterTextData'>
                        {patientsQuery.isFetching ? <CircularProgress size={16} /> : `${DisplayGOPCount}`}
                    </span>
                </div>
            </Button>
        </Tooltip>
        <ChevronRightIcon />
        <Tooltip title={searchFilter?.missingProforma === false ?
            <Typography>
                {TextLanguage["Uploaded"][lang]}
                {' '}
                {TextLanguage["Proforma"][lang]}
            </Typography>
            :
            <Typography>
                {TextLanguage["Awaiting"][lang]}
                {' '}
                {TextLanguage["Proforma"][lang]}
            </Typography>} placement="top">
            <Button onClick={handleMissingProformaFilter} className={`counterBox ${
                (searchFilter?.missingProforma !== undefined) ?
                        (searchFilter?.missingProforma ? "counterBoxActiveMissing" : "counterBoxActive" )
                        :
                        (null)
                        }`}>
                <DescriptionIcon htmlColor={theme.palette.dashboardTheme.Proforma} sx={{ m: '0px 0px 0px 0px', fontSize: '1.5rem' }} />
                <div className='counterTextBox'>

                    <span className='counterTextData'>
                         {patientsQuery.isFetching ? <CircularProgress size={16} /> : `${DisplayProformaCount}`}
                    </span>
                </div>
            </Button>
        </Tooltip>
        <ChevronRightIcon />
        <Tooltip title={searchFilter?.missingInvoice === false ?
            <Typography>
                {TextLanguage["Uploaded"][lang]}
                {' '}
                {TextLanguage["Invoice"][lang]}
            </Typography>
            :
            <Typography>
                {TextLanguage["Awaiting"][lang]}
                {' '}
                {TextLanguage["Invoice"][lang]}
            </Typography>} placement="top">
            <Button onClick={handleMissingInvoiceFilter} className={`counterBox ${searchFilter?.missingInvoice !== undefined ?
                searchFilter?.missingInvoice ? "counterBoxActiveMissing" : "counterBoxActive" : null}`}>
                <ReceiptIcon htmlColor={theme.palette.dashboardTheme.Invoice} sx={{ m: '0px 0px 0px 0px', fontSize: '1.5rem' }} />
                <div className='counterTextBox'>

                    <span className='counterTextData'>
                        {patientsQuery.isFetching ? <CircularProgress size={16} /> : `${DisplayInvoiceCount}`}
                    </span>
                </div>
            </Button>
        </Tooltip>
    </>);
}
