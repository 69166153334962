import React, { useState } from 'react';
import { ActivityLogItem } from './ActivityLogItem';
import { usePatientActivityLogQuery } from '../../../hooks/usePatientActivityLogQuery';
import {
    Autocomplete,
    Button,
    Chip,
    TextField,
    Typography
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PersonIcon from '@mui/icons-material/Person';

import { useQueryClient } from '@tanstack/react-query';
import TextLanguage from '../../../../../data/TextLanguage.json';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { styled, useTheme } from '@mui/material/styles';

const FilterButton = styled(Button)(({ theme }) => ({
    color: 'white',
    background: 'linear-gradient(to bottom, rgba(52,84,129,0.28), rgba(66, 151, 255, 0.28))',

    borderRadius: 0,
    padding: '4px !important',
    alignItems: 'center',
    alignSelf: 'center',

    border: '1px solid rgba(22, 22, 22, 0.75)',
    boxShadow: 'inset 0px 0px 20px 0px rgba(38, 38, 38, 0.45),0px 0px 12px -2px rgba(22, 22, 22, 0.6)',
    zIndex: 200,
    justifyContent: 'center',
    transition: 'all 0.2s ease',


    height: '100%',
    minWidth: '32px ',
    '&:hover, &.Mui-focusVisible': {
        background: 'linear-gradient(to bottom, rgba(52,84,129,0.28), rgba(66, 151, 255, 0.28))',
        boxShadow: 'inset 0px 0px 8px 2px rgba(125,188,255, 0.15 )',
        outline: 'none',
    },
}));


export const ActivityLog = ({
    RBKCaseNo,
    setformOpen,
    setselectedRow,
    setSelectedTab,
    setmodalPosition,
    setFormCreateMode,
    mainActivityLog = true,
    disableClick = false,
    lang
}) => {

    const theme = useTheme();

    const [hospSelectOpen, setHospSelectOpen] = useState(false);
    const [selectedHospID, setSelectedHospID] = useState(null); // {label: '...' (Name) , value: '...' (#ID)}

    const [logFilter, setLogFilter] = useState({ logType: null, hospital: null, userType: null });

    const activityLogFetchQuery = usePatientActivityLogQuery({ RBKCaseNo: !!RBKCaseNo ? RBKCaseNo : null });

    const queryClient = useQueryClient();
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    const handleFilterUploadButton = () => {
        if (logFilter.logType !== 'Upload') {
            setLogFilter({ ...logFilter, logType: 'Upload' });
        } else {
            setLogFilter({ ...logFilter, logType: null });
        }
    };

    const handleFilterUserButton = () => {
        if (!logFilter.userType) {
            setLogFilter({ ...logFilter, userType: 'Client' });
        } else {
            setLogFilter({ ...logFilter, userType: null });
        }
    };

    const handleFilterHospButton = () => {
        if (hospSelectOpen) {
            setSelectedHospID(null);
            setLogFilter({ ...logFilter, hospital: null });
        }
        setHospSelectOpen(!hospSelectOpen);
    };

    const handleHospitalFilterSelect = (event, value) => {
        setSelectedHospID(value);
        setLogFilter({ ...logFilter, hospital: value?.hospital });
    };




    const activityLogData = activityLogFetchQuery?.data?.data;

    const activityLogHospitalAggregation = activityLogData?.reduce((acc, log) => {
        const indexOf = acc.findIndex((hosp) => hosp.hospital === log.hospital);
        if (indexOf !== -1) {
            acc[indexOf].logs.push(log);
            acc[indexOf].count += 1;
        } else {
            acc.push({
                label: hospitalUserListData?.data?.find((hosp) => hosp.value === log.hospital)?.label,
                hospital: log.hospital,
                logs: [log],
                count: 1
            });
        }
        return acc;
    }, [])?.sort((a, b) => b.count - a.count);


    const activityLogDataFiltered = activityLogData?.filter((log) => {
        const logTypeFilter = logFilter.logType === 'Upload' ? log.logType.includes('Upload') : true;
        const hospitalFilter = !!logFilter.hospital ? log.hospital === logFilter.hospital : true;
        const userTypeFilter = !!logFilter.userType ? log.logUserType === logFilter.userType : true;

        return logTypeFilter && hospitalFilter && userTypeFilter;
    });


    return (
        <div className='InfoActivityLogSection'>

            <div className='ActivityLogNavBar'>
                <div className='ActivityLogNavBarTop' >
                    <Typography variant='h6' sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        userSelect: 'none'
                    }}>
                        {TextLanguage["RecentActivity"][lang]}
                    </Typography>



                    <div>
                    <FilterButton onClick={handleFilterUserButton}>
                            <PersonIcon sx={{ color: !!logFilter.userType ? 'white' : theme.palette.shadow[0], fontSize: '1.5rem' }} />
                        </FilterButton>
                      {mainActivityLog &&  <FilterButton onClick={handleFilterHospButton}>
                            <FilterAltIcon sx={{ color: !!logFilter.hospital ? 'white' : theme.palette.shadow[0], fontSize: '1.5rem' }} />
                        </FilterButton>}

                        <FilterButton onClick={handleFilterUploadButton}   >
                            <InsertDriveFileIcon sx={{ color: logFilter.logType === 'Upload' ? 'white' : theme.palette.shadow[0], fontSize: '1.5rem' }} />
                        </FilterButton>
                    </div>
                </div>

                {hospSelectOpen && <div className='ActivityLogNavBarBottom'>
                    <Autocomplete
                        size={'small'}
                        selectOnFocus
                        clearOnBlur
                        value={selectedHospID}
                        onChange={handleHospitalFilterSelect}
                        options={activityLogHospitalAggregation}
                        renderOption={(props, option) =>
                        <li {...props} style={{ background: 'transparent', justifyContent: 'space-between', width:'100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem', width: '100%' }}>
                            <Typography sx={{width:'100%'}}>{option.label}</Typography>
                            <Chip key={`${option.hospital}-chip`} variant="outlined"
                                sx={{
                                    border: '1px solid #ffffff29',
                                    color: 'white',
                                    '& .MuiChip-label': { padding: '0px' },
                                    background: 'rgba(70, 100, 160, 0.65)',
                                    fontWeight: 900, fontSize: '14px',
                                    borderRadius: '100%',
                                    width: '28px',
                                    height: '28px',
                                }}
                                label={option.count} />
                        </div>
                        </li>}
                        renderInput={(params) => <TextField {...params} label="Hospital" />}
                        sx={{ width: '100% !important', minWidth: '100px !important', maxWidth: '100% !important',
                            '& .MuiFormControl-root': { width: '100% !important' },
                         }}
                    />
                </div>}

            </div>

            {activityLogFetchQuery.isSuccess && activityLogDataFiltered?.map((log, index) => {
                return (

                    <ActivityLogItem key={`ActivityLogItem-${index}`}
                        setformOpen={setformOpen}
                        setselectedRow={setselectedRow}
                        setSelectedTab={setSelectedTab}
                        setmodalPosition={setmodalPosition}
                        setFormCreateMode={setFormCreateMode}
                        disableClick={disableClick}

                        log={log}
                        index={index}
                        hospitalLabel={
                            hospitalUserListData?.data?.find((hosp) => { return (hosp.value === log.hospital) })?.label

                        }
                        mainActivityLog={mainActivityLog}
                    />


                )
            })}







        </div>

    )
}
