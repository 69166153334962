import React from 'react';
import { Typography } from '@mui/material';
import { usePatientsQuery } from '../../../../../hooks/usePatientsQuery';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)

const userTypesRBK = ['Operator', 'Admin', 'Expertise'];

function TimestampConverter(time) {
    const date = new Date(time);
    const dateString = date.toISOString();
    const year = dateString.split('-')[0];
    const month = dateString.split('-')[1];
    const day = dateString.split('-')[2].split('T')[0];
    const timeString = dateString.split('T')[1].split('.')[0];
    const dateParsedString = `${day}.${month}.${year}`;

    const fromNowTime = dayjs(date).fromNow();

    const currentTime = new Date(Date.now());
    const diff = currentTime - date;
    if (diff < 86400000) {
        return `${fromNowTime}`;
    } else {
        return `${dateParsedString} ${timeString.split(':')[0]}:${timeString.split(':')[1]}`;
    }

}



const logTypeMap = {
    patientInfo: 'Updated Patient Info',
    addHospital: 'Sent to New Hospital',
    removeHospital: 'Cancelled Hospital',
    UpdatePatient: 'Updated Patient Info',
    UpdateGOP: 'Updated GOP',
    ReviewCase: 'Reviewed Case',
    NewMessage: 'Sent Message',
    NewPatient: 'Created Patient Case',
    RemovedCase: 'Removed Patient Case',
    UploadDocuments: 'Uploaded Documents',
    UploadProforma: 'Uploaded Proforma',
    UploadInvoice: 'Uploaded Invoice',
}

export const ActivityLogItem = ({ log, index, hospitalLabel, mainActivityLog,
    setformOpen,
    setselectedRow,
    setSelectedTab,
    setmodalPosition,
    setFormCreateMode,
    disableClick = false


}) => {

    const singlePatientQuery = usePatientsQuery({disableClick ,page: 0, pageSize: 1, sortField: null, sortOrder: null, searchFilter: { caseNumber: log.RBKCaseNo } });


    const handleItemClick =  () => {
        if (log.logType === 'RemovedCase') { return }

        if (singlePatientQuery.isLoading || singlePatientQuery.isError || !singlePatientQuery?.data?.data?.patientList[0]) { return }


         setSelectedTab("PatientCaseInformation");
         setselectedRow(singlePatientQuery?.data?.data?.patientList[0]);
        console.log('singlePatientQuery',singlePatientQuery?.data?.data?.patientList[0]);
        //  !(msg?.value?.by === 'Operator') && setselectedhospCaseID(msg?.value?.by);
        setformOpen(true);
        setTimeout(() => {
            setmodalPosition('0%')
        }, 15);
        setFormCreateMode(false);

    };



    return (<div onClick={handleItemClick} className={`ActivityLogItemContainer ${userTypesRBK.includes(log.logUserType) ? `ActivityLogItemContainerOperator` : `ActivityLogItemContainerClient`}`}>
        <Typography sx={{ userSelect:'none',position: 'absolute', bottom: 4, right: 8, fontSize: '0.8rem', color: 'lightgray' }}>
            {`${TimestampConverter(log.timestamp)}`}
        </Typography>
        <div style={{ userSelect:'none',display: 'flex', flexDirection: 'row', gap: '0.2rem' }}>
            <Typography sx={{userSelect:'none', fontWeight: 'bold' }}>
                {`${log.logUser}:`}
            </Typography>
            {logTypeMap[log.logType] && <Typography sx={{userSelect:'none'}}>
                {`${logTypeMap[log.logType]}`}
            </Typography>}
        </div>
        {hospitalLabel && <Typography sx={{userSelect:'none', fontSize: '0.95rem' }}>
            {`${hospitalLabel}`}
        </Typography>}

        {
            mainActivityLog ? <Typography sx={{ userSelect:'none',fontSize: '0.95rem', fontWeight:'bold' }}>
                {`${log.RBKCaseNo}`}
            </Typography> : null
        }

    </div>);
}
